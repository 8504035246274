.custom-button {
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 5px;
}

.button.alt a {
    padding: 16px 20px;
}

.button a {
    display: inline-flex;
    align-items: center;
}

.hytPlayerWrap iframe {
    max-width: 100%;
}

.page h3 {
    text-align: center;
}

.github.button {
    text-align: center;
    margin: 50px 0;
}
.github.button .custom-button {
    top: -1px;
    margin-right: 10px;
}
.github.button a {
    background-color: #28a745;
    border: none;
}
.github.button a:hover {
    background-color: #208236;
}
.contact-main {
    margin-top: 50px;
}
.contact-box {
    max-width: 500px;
}
.contact-email {
    text-align: center;
}

.testimonials {
    background-color: #2c5ca5;
    padding: 60px 0;
}

.testimonials.top {
    margin-bottom: 100px;
}

.testimonials, .testimonials p {
    color: white;
    text-align: center;
}

.testimonials-inner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    padding: 0 20px;
}

.testimonials-inner > div {
    width: 370px;
    padding: 0 20px;
}

.testimonials .fa-star {
    margin: 0 3px;
    color: #f2d65f;
}

.testimonials .attribution {
    font-size: 1em;
}

@media (min-width: 1450px) {
    .testimonials-inner {
        flex-wrap: nowrap;
    }
}

.hero {
    padding-bottom: 0;
    overflow: auto;
}

.content {
    padding: 0;
}

iframe {
    max-width: 100%;
    border: solid white 3px;
    box-shadow: 5px 5px 5px rgba(0,0,0,.1);
}