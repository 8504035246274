.plans {
	@extend %flexbox;
	@include flex-flow(wrap);
	padding: 50px 0 30px 0;

	.plan {
		list-style: none;
		padding: 0;
		margin: 0 10px 50px 10px;
		text-align: center;
		border: 1px solid #eee;
		border-radius: 5px;
		box-shadow: 0px 0px 10px #eee;
		width: 100%;

		.highlighted {
			font-size: 1.2em
		}

		.pricing-cta {
			padding: 0;

			a {
				display: block;
				box-sizing: border-box;
				padding: 20px 0;
				border-radius: 0 0 2px 2px;
				border: 0;
			}
		}

		@media #{$desktop} {
			-webkit-flex: 1;
			flex: 1;
		}

		li {
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
			padding: 20px 0;
			h3 {
				padding: 0;
				margin: 0;
				color: #fff;
				font-weight: normal;
			}
			a {
				color: #fff;
			}
		}

		small {
			color: #aaa;
		}
	}
}

.faq {
	@media #{$desktop} {
		@include columns(2);
	}
	color: #666;
	div {
		break-inside: avoid;
		padding: 25px 0;
	}

	dt {
		font-weight: bold;
		margin: 0 0 5px 0;
	}

	dd {
		padding: 0;
		margin: 0;

	}
}
